const cta_buttons = document.querySelectorAll(".cta a");
const sub_container_one = document.querySelectorAll(".sub_container")[0];
const sub_container_two = document.querySelectorAll(".sub_container")[1];

cta_buttons?.forEach(function (button) {
  button.addEventListener("click", (event) => {
    sub_container_two.scrollIntoView();
  });
});

const return_arrow = document.querySelector(".return_arrow img");

return_arrow?.addEventListener("click", () => {
  sub_container_one.scrollIntoView();
});

const inputs = document.querySelectorAll("input");

inputs.forEach((input) => {
  input.addEventListener("focus", (e) => {
    e.preventDefault();
    document.body.setAttribute("style", "scroll-snap-type: none;");
  });
  input.addEventListener("blur", (e) => {
    e.preventDefault();
    document.body.setAttribute("style", "scroll-snap-type: y mandatory;");
  });
});

const forms = document.querySelectorAll("form");

forms?.forEach((form) => {
  form.addEventListener("submit", (e) => {
    e.preventDefault();
    const form = e.target;
    const email = form.querySelector('input[name="email"]')?.value;
    if (validateEmail(email)) {
      const formData = new FormData(form);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          console.log("Form successfully submitted");
          disableModal();
          window.location.href = "/thankyou.html";
        })
        .catch((error) => {
          alert(error);
          enableModal();
        });
    } else {
      enableModal();
    }
  });
});

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const blur = document.getElementById("blur");
blur?.addEventListener("click", disableModal);

const modal_error = document.querySelectorAll(".modal_error");

function enableModal() {
  blur?.setAttribute("class", "blury");
  modal_error[0].setAttribute("style", "");
  modal_error[0].setAttribute("class", "error_one");

  modal_error[1].setAttribute("style", "");
  modal_error[1].setAttribute("class", "error_two");
}

function disableModal() {
  blur?.setAttribute("class", "");
  modal_error?.forEach((element) => {
    element.setAttribute("style", "display: none;");
    element.setAttribute("class", "");
  });
}

document.addEventListener("keydown", (e) => {
  if (e.key === "Escape") {
    disableModal();
  }
});
